.page-border {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    margin: 1.5%;
}
.page-header {
    color: $fmh-dash-font-color-blue;
    font-weight: $fmh-dash-font-weight-medium;
    font-size: $fmh-dash-font-size-large;
}
.page-sub-header {
    color: $fmh-dash-font-color-blue;
    font-size: $fmh-dash-font-size-large;
}
.footer-note {
    display: flex;
    flex-grow: 1;
    color: $fmh-dash-color-gray-500;
}